import { Component } from "react";
import { artist } from "../db";
import { Link } from "react-router-dom";

class MobileArtists extends Component {
  render() {
    return (
      <div>
        <nav id="nav-mobile">
          {artist.map((x) => {
            return <Link to={"/artist/" + x.id}>{x.name}</Link>;
          })}
          <Link to="/contact">kontakt</Link>
          <Link to="/imprint">imprint</Link>
        </nav>
      </div>
    );
  }
}
export default MobileArtists;
