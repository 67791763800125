import React, { Component } from "react";
import "./Aside";

import Aside from "./Aside";
import { artist, main } from "../db";
import { Link } from "react-router-dom";
import Header from "./Header";

class ArtistInfo extends Component {
  state = {
    videoUrl: main,
    x: 0,
    y: 0,
  };

  handleMouseMove(e) {
    this.setState({ x: e.nativeEvent.clientX, y: e.nativeEvent.clientY });
  }
  render() {
    var video = document.getElementById("preview-video");
    return (
      <main
        onMouseMove={(e) => {
          this.setState({ x: e.nativeEvent.clientX, y: e.nativeEvent.clientY });
        }}
      >
        <footer className="border-right">
          <a href="imprint">Imprint</a>
        </footer>
        <nav className="border-right">
          {artist.map((x) => {
            return (
              <Link
                onMouseEnter={(e) => {
                  this.setState({
                    videoUrl: x.id.toLowerCase() + ".mp4",
                  });
                  if ((e.currentTarget.getBoundingClientRect().height / e.currentTarget.getBoundingClientRect().width) <1 ){
                    video.style.objectFit = "cover";
                  }
                  
                }}
                onMouseLeave={() => {
                  this.setState({ videoUrl: main });
                  video.style.transform = "";

                  video.style.objectFit = "contain";
                }}
                onMouseMove={(e) => {
                  e.currentTarget.getBoundingClientRect();
                  let percX =
                    (((this.state.x -
                      e.currentTarget.getBoundingClientRect().x +
                      e.currentTarget.getBoundingClientRect().width / 2) /
                      e.currentTarget.getBoundingClientRect().width) *
                      100 -
                      100) *
                    2;
                  let percY =
                    (((this.state.y -
                      e.currentTarget.getBoundingClientRect().y +
                      e.currentTarget.getBoundingClientRect().height / 2) /
                      e.currentTarget.getBoundingClientRect().height) *
                      100 -
                      100) *
                    2;
                  /*  video.style.transform =
                    "translate(" + percX / 10 + "%," + percY / 25 + "%)"; */

                  video.style.transform =
                    "translate(" + percX / 10 + "%," + percY / 25 + "%)";
                  console.log("translate(" + percX + "%," + percY + "%)");
                }}
                to={"/artist/" + x.id}
              >
                {x.name}{" "}
              </Link>
            );
          })}{" "}
        </nav>

        <section id="preview" className="border-right">
          <div>
            <video
              id="preview-video"
              src={this.state.videoUrl}
              autoPlay
              muted
              loop
            ></video>
          </div>
        </section>
        <Aside x={this.state.x} y={this.state.y}></Aside>
      </main>
    );
  }
}

export default ArtistInfo;
