import React, { Component } from "react";
import { Link } from "react-router-dom";

class Header extends Component {
  render() {
    return (
      <header id="header-mobile">
        <Link to="/">
          <img src="/header_mobile.svg"></img>
        </Link>
      </header>
    );
  }
}
export default Header;
