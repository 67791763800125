import { Component } from "react";

class MobileFooter extends Component {
  render() {
    return (
      <span id="footer-mobile">
        <a href="/contact" className="round">Kontakt</a>
        <a href="/imprint" className="round">Imprint</a>
      </span>
    );
  }
}
export default MobileFooter;
