const Imprint = () => {
  return (
    <div className="imprint">
      <h1>COPYRIGHT &amp; IMPRINT</h1>
      <p></p>
      <h2>two clever GmbH</h2>
      <p>
        Uhlandstraße 171/172
        <br />
        10719 Berlin
        <br />
        Germany
      </p>
      <p>E-Mail:&nbsp;contact (at) touchingscreens.de</p>
      <p>
        Geschäftsführer: Roger Dan Nussbaum
        <br />
        AG Charlottenburg HRB 206960 B<br />
        Steuernummer: 27/565/50309
      </p>
      <p>
        DATENSCHUTZERKLÄRUNG
        <br /><br />* 1 Allgemeines
        <br /><br />
        Ihre personenbezogenen Daten (z.B. Anrede, Name, Anschrift,
        E-Mail-Adresse, Telefonnummer) werden von uns nur gemäß den Bestimmungen
        des deutschen Datenschutzrechts und des Datenschutzrechts der
        Europäischen Union (EU) verarbeitet. Die nachfolgenden Vorschriften
        informieren Sie neben den Verarbeitungszwecken, Empfängern,
        Rechtsgrundlagen, Speicherfristen auch über Ihre Rechte und den
        Verantwortlichen für Ihre Datenverarbeitung. Diese Datenschutzerklärung
        bezieht sich nur auf unsere Websites. Falls Sie über Links auf unseren
        Seiten auf andere Seiten weitergeleitet werden, informieren Sie sich
        bitte dort über den jeweiligen Umgang mit Ihren Daten.
        <br /><br />* 2 Informationen über Cookies
        <br /><br />
        (1) Verarbeitungszweck
        <br /><br />
        Auf dieser Webseite werden technisch notwendige Cookies eingesetzt. Es
        handelt sich dabei um kleine Textdateien, die im bzw. von Ihrem
        Internetbr/owser auf Ihrem Computersystem gespeichert werden.
        <br /><br />
        (2) Rechtsgrundlage
        <br /><br />
        Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs.1 f) DSGVO.
        <br /><br />
        (3) Berechtigtes Interesse
        <br /><br />
        Unser berechtigtes Interesse ist die Funktionsfähigkeit unserer
        Webseite. Die durch technisch notwendige Cookies erhobenen Nutzerdaten
        werden nicht zur Erstellung von Nutzerprofilen verwendet. Dadurch wird
        Ihr Interesse am Datenschutz gewahrt.
        <br /><br />
        (4) Speicherdauer
        <br /><br />
        Die technisch notwendigen Cookies werden im Regelfall mit dem Schließen
        des br/owsers gelöscht.Dauerhaft gespeicherte Cookies haben eine
        unterschiedlich lange Lebensdauer von einigen Minuten bis zu mehreren
        Jahren.
        <br /><br />
        (5) Widerspruchsrecht
        <br /><br />
        Falls Sie die Speicherung dieser Cookies nicht wünschen, deaktivieren
        Sie bitte die Annahme dieser Cookies in Ihrem Internetbr/owser. Dies
        kann aber eine Funktionseinschränkung unserer Webseite zur Folge haben.
        Dauerhaftgespeicherte Cookies können Sie ebenfalls jederzeit über Ihren
        br/owser löschen.
      </p>
      <p>
        * 3 Rechte der betroffenen Person
        <br /><br />
        Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie
        Betroffener i.S.d. DSGVO und es stehen Ihnen folgende Rechte uns
        gegenüber zu:
        <br /><br />
        1. Auskunftsrecht
        <br /><br />
        Sie können von uns eine Bestätigung darüber verlangen, ob
        personenbezogene Daten, die Sie betreffen, von uns verarbeitet werden.
        <br /><br />
        Liegt eine solche Verarbeitung vor, können Sie von uns über folgende
        Informationen Auskunft verlangen:
        <br /><br />
        (1) die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden;
        <br /><br />
        (2) die Kategorien von personenbezogenen Daten, welche verarbeitet
        werden;
        <br /><br />
        (3) die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen
        die Sie betreffenden personenbezogenen Daten offengelegt wurden oder
        noch offengelegt werden;
        <br /><br />
        (4) die geplante Dauer der Speicherung der Sie betreffenden
        personenbezogenen Daten oder, falls konkrete Angaben hierzu nicht
        möglich sind, Kriterien für die Festlegung der Speicherdauer;
        <br /><br />
        (5) das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie
        betreffenden personenbezogenen Daten, eines Rechts auf Einschränkung der
        Verarbeitung durch uns oder eines Widerspruchsrechts gegen diese
        Verarbeitung;
        <br /><br />
        (6) das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;
        <br /><br />
        (7) alle verfügbaren Informationen über die Herkunft der Daten, wenn die
        personenbezogenen Daten nicht bei der betroffenen Person erhoben werden;
        <br /><br />
        (8) das Bestehen einer automatisierten Entscheidungsfindung
        einschließlich Profiling gemäß Art.&nbsp;22 Abs.&nbsp;1 und 4 DSGVO und
        – zumindest in diesen Fällen – aussagekräftige Informationen über die
        involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen
        einer derartigen Verarbeitung für die betroffene Person.
      </p>
      <p>
        Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die Sie
        betreffenden personenbezogenen Daten in ein Drittland oder an eine
        internationale Organisation übermittelt werden. In diesem Zusammenhang
        können Sie verlangen, über die geeigneten Garantien gem. Art.&nbsp;46
        DSGVO im Zusammenhang mit der Übermittlung unterrichtet zu werden.
        <br /><br />
        2. Recht auf Berichtigung
        <br /><br />
        Sie haben ein Recht auf Berichtigung und/oder Vervollständigung uns
        gegenüber, sofern die verarbeiteten personenbezogenen Daten, die Sie
        betreffen, unrichtig oder unvollständig sind. Wir müssen die
        Berichtigung unverzüglich vornehmen.
        <br /><br />
        3. Recht auf Einschränkung der Verarbeitung
        <br /><br />
        Unter den folgenden Voraussetzungen können Sie die Einschränkung der
        Verarbeitung der Sie betreffenden personenbezogenen Daten verlangen:
        <br /><br />
        (1) wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen
        Daten für eine Dauer bestreiten, die es uns ermöglicht, die Richtigkeit
        der personenbezogenen Daten zu überprüfen;
        <br /><br />
        (2) wenn die Verarbeitung unrechtmäßig ist und Sie die Löschung der
        personenbezogenen Daten ablehnen und stattdessen die Einschränkung der
        Nutzung der personenbezogenen Daten verlangen;
        <br /><br />
        (3) wenn wir die personenbezogenen Daten für die Zwecke der Verarbeitung
        nicht länger benötigen, Sie diese jedoch zur Geltendmachung, Ausübung
        oder Verteidigung von Rechtsansprüchen benötigen, oder
        <br /><br />
        (4) wenn Sie Widerspruch gegen die Verarbeitung gemäß Art.&nbsp;21
        Abs.&nbsp;1 DSGVO eingelegt haben und noch nicht feststeht, ob die uns
        zustehenden berechtigten Gründe gegenüber Ihren Gründen überwiegen.
        <br /><br />
        Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten
        eingeschränkt, dürfen diese Daten – von ihrer Speicherung abgesehen –
        nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder
        Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer
        anderen natürlichen oder juristischen Person oder aus Gründen eines
        wichtigen öffentlichen Interesses der Union oder eines Mitgliedstaats
        verarbeitet werden.
        <br /><br />
        Wurde die Einschränkung der Verarbeitung nach den oben genannten
        Voraussetzungen eingeschränkt, werden Sie von uns unterrichtet bevor die
        Einschränkung aufgehoben wird.
        <br /><br />
        4. Recht auf Löschung
        <br /><br />
        5. a) Löschungspflicht
        <br /><br />
        Sie können von uns verlangen, dass die Sie betreffenden
        personenbezogenen Daten unverzüglich gelöscht werden, und wir sind
        verpflichtet, diese Daten unverzüglich zu löschen, sofern einer der
        folgenden Gründe zutrifft:
        <br /><br />
        (1) Die Sie betreffenden personenbezogenen Daten sind für die Zwecke,
        für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht
        mehr notwendig.
        <br /><br />
        (2) Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem.
        Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;a oder Art.&nbsp;9 Abs.&nbsp;2
        lit.&nbsp;a DSGVO stützte, und es fehlt an einer anderweitigen
        Rechtsgrundlage für die Verarbeitung.
        <br /><br />
        (3) Sie legen gem. Art.&nbsp;21 Abs.&nbsp;1 DSGVO Widerspruch gegen die
        Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe für
        die Verarbeitung vor, oder Sie legen gem. Art.&nbsp;21 Abs.&nbsp;2 DSGVO
        Widerspruch gegen die Verarbeitung ein.
        <br /><br />
        (4) Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig
        verarbeitet.
        <br /><br />
        (5) Die Löschung der Sie betreffenden personenbezogenen Daten ist zur
        Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem
        Recht der Mitgliedstaaten erforderlich, dem wir unterliegen.
        <br /><br />
        (6) Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf
        angebotene Dienste der Informationsgesellschaft gemäß Art.&nbsp;8
        Abs.&nbsp;1 DSGVO erhoben.
        <br /><br />
        1. b) Information an Dritte
        <br /><br />
        Haben wir die Sie betreffenden personenbezogenen Daten öffentlich
        gemacht und sind wir gem. Art.&nbsp;17 Abs.&nbsp;1 DSGVO zu deren
        Löschung verpflichtet, so treffen wir unter Berücksichtigung der
        verfügbaren Technologie und der Implementierungskosten angemessene
        Maßnahmen, auch technischer Art, um die für die Datenverarbeitung
        Verantwortlichen, die die personenbezogenen Daten verarbeiten, darüber
        zu informieren, dass Sie als betroffene Person von ihnen die Löschung
        aller Links zu diesen personenbezogenen Daten oder von Kopien oder
        Replikationen dieser personenbezogenen Daten verlangt haben.
        <br /><br />
        1. c) Ausnahmen
        <br /><br />
        Das Recht auf Löschung besteht nicht, soweit die Verarbeitung
        erforderlich ist
        <br /><br />
        (1) zur Ausübung des Rechts auf freie Meinungsäußerung und Information;
        <br /><br />
        (2) zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung
        nach dem Recht der Union oder der Mitgliedstaaten, dem wir unterliegen,
        erfordert, oder zur Wahrnehmung einer Aufgabe, die im öffentlichen
        Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die uns
        übertragen wurde;
        <br /><br />
        (3) aus Gründen des öffentlichen Interesses im Bereich der öffentlichen
        Gesundheit gemäß Art.&nbsp;9 Abs.&nbsp;2 lit.&nbsp;h und i sowie
        Art.&nbsp;9 Abs.&nbsp;3 DSGVO;
        <br /><br />
        (4) für im öffentlichen Interesse liegende Archivzwecke,
        wissenschaftliche oder historische Forschungszwecke oder für
        statistische Zwecke gem. Art.&nbsp;89 Abs.&nbsp;1 DSGVO, soweit das
        unter Abschnitt a) genannte Recht voraussichtlich die Verwirklichung der
        Ziele dieser Verarbeitung unmöglich macht oder ernsthaft beeinträchtigt,
        oder
        <br /><br />
        (5) zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
        <br /><br />
        5. Recht auf Unterrichtung
        <br /><br />
        Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der
        Verarbeitung uns gegenüber geltend gemacht, sind wir verpflichtet, allen
        Empfängern, denen die Sie betreffenden personenbezogenen Daten
        offengelegt wurden, diese Berichtigung oder Löschung der Daten oder
        Einschränkung der Verarbeitung mitzuteilen, es sei denn, dies erweist
        sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand
        verbunden.
        <br /><br />
        Ihnen steht uns gegenüber das Recht zu, über diese Empfänger
        unterrichtet zu werden.
        <br /><br />
        6. Recht auf Datenübertragbarkeit
        <br /><br />
        Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die
        Sie uns bereitgestellt haben, in einem strukturierten, gängigen und
        maschinenlesbaren Format zu erhalten. Außerdem haben Sie das Recht diese
        Daten einem anderen Verantwortlichen ohne Behinderung zu übermitteln,
        sofern
        <br /><br />
        (1) die Verarbeitung auf einer Einwilligung gem. Art.&nbsp;6 Abs.&nbsp;1
        lit.&nbsp;a DSGVO oder Art.&nbsp;9 Abs.&nbsp;2 lit.&nbsp;a DSGVO oder
        auf einem Vertrag gem. Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;b DSGVO beruht
        und
        <br /><br />
        (2) die Verarbeitung mithilfe automatisierter Verfahren erfolgt.
        <br /><br />
        In Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken, dass
        die Sie betreffenden personenbezogenen Daten direkt von uns einem
        anderen Verantwortlichen übermittelt werden, soweit dies technisch
        machbar ist. Freiheiten und Rechte anderer Personen dürfen hierdurch
        nicht beeinträchtigt werden.
        <br /><br />
        Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung
        personenbezogener Daten, die für die Wahrnehmung einer Aufgabe
        erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung
        öffentlicher Gewalt erfolgt, die uns übertragen wurde.
        <br /><br />
        7. Widerspruchsrecht
        <br /><br />
        Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen
        Situation ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden
        personenbezogenen Daten, die aufgrund von Art.&nbsp;6 Abs.&nbsp;1
        lit.&nbsp;e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch
        für ein auf diese Bestimmungen gestütztes Profiling.
        <br /><br />
        Wir verarbeiten die Sie betreffenden personenbezogenen Daten dann nicht
        mehr, es sei denn, wir können zwingende schutzwürdige Gründe für die
        Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten
        überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung
        oder Verteidigung von Rechtsansprüchen.
        <br /><br />
        Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um
        Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch
        gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten zum
        Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling,
        soweit es mit solcher Direktwerbung in Verbindung steht.
        <br /><br />
        Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so
        werden die Sie betreffenden personenbezogenen Daten nicht mehr für diese
        Zwecke verarbeitet.
        <br /><br />
        Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von Diensten
        der Informationsgesellschaft – ungeachtet der Richtlinie 2002/58/EG –
        Ihr Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei
        denen technische Spezifikationen verwendet werden.
        <br /><br />
        8. Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung
        <br /><br />
        Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung
        jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird die
        Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten
        Verarbeitung nicht berührt.
        <br /><br />
        9. Automatisierte Entscheidung im Einzelfall einschließlich Profiling
        <br /><br />
        Sie haben das Recht, nicht einer ausschließlich auf einer
        automatisierten Verarbeitung – einschließlich Profiling – beruhenden
        Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche
        Wirkung entfaltet oder Sie in ähnlicher Weise erheblich beeinträchtigt.
        Dies gilt nicht, wenn die Entscheidung
        <br /><br />
        (1) für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen
        und uns erforderlich ist,
        <br /><br />
        (2) aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten,
        denen wir unterliegen, zulässig ist und diese Rechtsvorschriften
        angemessene Maßnahmen zur Wahrung Ihrer Rechte und Freiheiten sowie
        Ihrer berechtigten Interessen enthalten oder
        <br /><br />
        (3) mit Ihrer ausdrücklichen Einwilligung erfolgt.
        <br /><br />
        Allerdings dürfen diese Entscheidungen nicht auf besonderen Kategorien
        personenbezogener Daten nach Art.&nbsp;9 Abs.&nbsp;1 DSGVO beruhen,
        sofern nicht Art.&nbsp;9 Abs.&nbsp;2 lit.&nbsp;a oder g gilt und
        angemessene Maßnahmen zum Schutz der Rechte und Freiheiten sowie Ihrer
        berechtigten Interessen getroffen wurden.
        <br /><br />
        Hinsichtlich der in (1) und (3) genannten Fälle treffen wir angemessene
        Maßnahmen, um die Rechte und Freiheiten sowie Ihre berechtigten
        Interessen zu wahren.
        <br /><br />
        10. Recht auf Beschwerde bei einer Aufsichtsbehörde
        <br /><br />
        Unbeschadet eines anderweitigen verwaltungsrechtlichen oder
        gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei
        einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat Ihres
        Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts des mutmaßlichen
        Verstoßes, zu, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie
        betreffenden personenbezogenen Daten gegen die DSGVO verstößt.
        <br /><br />
        Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde,
        unterrichtet den Beschwerdeführer über den Stand und die Ergebnisse der
        Beschwerde einschließlich der Möglichkeit eines gerichtlichen
        Rechtsbehelfs nach Art.&nbsp;78 DSGVO.
      </p>
      <p>
        Haftungsausschluss
        <br /><br />
        1. Inhalt des OnlineangebotesDer Autor übernimmt keinerlei Gewähr für
        die Aktualität, Korrektheit, Vollständigkeit oder Qualität der
        bereitgestellten Informationen. Haftungsansprüche gegen den Autor,
        welche sich auf Schäden materieller oder ideeller Art beziehen, die
        durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw.
        durch die Nutzung fehlerhafter und unvollständiger Informationen
        verursacht wurden, sind grundsätzlich ausgeschlossen, sofern seitens des
        Autors kein nachweislich vorsätzliches oder grob fahrlässiges
        Verschulden vorliegt. Alle Angebote sind freibleibend und unverbindlich.
        Der Autor behält es sich ausdrücklich vor, Teile der Seiten oder das
        gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen,
        zu löschen oder die Veröffentlichung zeitweise oder endgültig
        einzustellen.
        <br /><br />
        2. Verweise und LinksBei direkten oder indirekten Verweisen auf fremde
        Webseiten („Hyperlinks“), die außerhalb des Verantwortungsbereiches des
        Autors liegen, würde eine Haftungsverpflichtung ausschließlich in dem
        Fall in Kraft treten, in dem der Autor von den Inhalten Kenntnis hat und
        es ihm technisch möglich und zumutbar wäre, die Nutzung im Falle
        rechtswidriger Inhalte zu verhindern. Der Autor erklärt hiermit
        ausdrücklich, dass zum Zeitpunkt der Linksetzung keine illegalen Inhalte
        auf den zu verlinkenden Seiten erkennbar waren. Auf die aktuelle und
        zukünftige Gestaltung, die Inhalte oder die Urheberschaft der
        verlinkten/verknüpften Seiten hat der Autor keinerlei Einfluss. Deshalb
        distanziert er sich hiermit ausdrücklich von allen Inhalten aller
        verlinkten /verknüpften Seiten, die nach der Linksetzung verändert
        wurden. Diese Feststellung gilt für alle innerhalb des eigenen
        Internetangebotes gesetzten Links und Verweise sowie für Fremdeinträge
        in vom Autor eingerichteten Gästebüchern, Diskussionsforen,
        Linkverzeichnissen, Mailinglisten und in allen anderen Formen von
        Datenbanken, auf deren Inhalt externe Schreibzugriffe möglich sind. Für
        illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für
        Schäden, die aus der Nutzung oder Nichtnutzung solcherart dargebotener
        Informationen entstehen, haftet allein der Anbieter der Seite, auf
        welche verwiesen wurde, nicht derjenige, der über Links auf die
        jeweilige Veröffentlichung lediglich verweist.
        <br /><br />
        3. Urheber- und KennzeichenrechtDer Autor ist bestrebt, in allen
        Publikationen die Urheberrechte der verwendeten Bilder, Grafiken,
        Tondokumente, Videosequenzen und Texte zu beachten, von ihm selbst
        erstellte Bilder, Grafiken, Tondokumente, Videosequenzen und Texte zu
        nutzen oder auf lizenzfreie Grafiken, Tondokumente, Videosequenzen und
        Texte zurückzugreifen. Alle innerhalb des Internetangebotes genannten
        und ggf. durch Dritte geschützten Marken- und Warenzeichen unterliegen
        uneingeschränkt den Bestimmungen des jeweils gültigen Kennzeichenrechts
        und den Besitzrechten der jeweiligen eingetragenen Eigentümer. Allein
        aufgrund der bloßen Nennung ist nicht der Schluss zu ziehen, dass
        Markenzeichen nicht durch Rechte Dritter geschützt sind! Das Copyright
        für veröffentlichte, vom Autor selbst erstellte Objekte bleibt allein
        beim Autor der Seiten. Eine Vervielfältigung oder Verwendung solcher
        Grafiken, Tondokumente, Videosequenzen und Texte in anderen
        elektronischen oder gedruckten Publikationen ist ohne ausdrückliche
        Zustimmung des Autors nicht gestattet.
        <br /><br />
        4. DatenschutzSofern innerhalb des Internetangebotes die Möglichkeit zur
        Eingabe persönlicher oder geschäftlicher Daten (Emailadressen, Namen,
        Anschriften) besteht, so erfolgt die Preisgabe dieser Daten seitens des
        Nutzers auf ausdrücklich freiwilliger Basis. Die Inanspruchnahme und
        Bezahlung aller angebotenen Dienste ist – soweit technisch möglich und
        zumutbar – auch ohne Angabe solcher Daten bzw. unter Angabe
        anonymisierter Daten oder eines Pseudonyms gestattet. Die Nutzung der im
        Rahmen des Impressums oder vergleichbarer Angaben veröffentlichten
        Kontaktdaten wie Postanschriften, Telefon- und Faxnummern sowie
        Emailadressen durch Dritte zur Übersendung von nicht ausdrücklich
        angeforderten Informationen ist nicht gestattet. Rechtliche Schritte
        gegen die Versender von sogenannten Spam-Mails bei Verstössen gegen
        dieses Verbot sind ausdrücklich vorbehalten.
      </p>
      <p></p>
    </div>
  );
};
export default Imprint;
