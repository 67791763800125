import { Component, useEffect } from "react";
import Header from "./Header";
import Artists from "./Artists";
import Artist from "./Artist";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import Contact from "./Contact"
import Imprint from "./Imprint"

import { Swipe, Position } from "react-swipe-component";
import {
  BrowserRouter as Router,
  Switch,
  useParams,
  Route,
  Link,
  useHistory,
} from "react-router-dom";
function ArtistComp() {
  let { name } = useParams();
  console.log(name);
  console.log("here");
  return <Artist id={name} />;
}
const FirstVisit = () => {
  let history = useHistory();
  function greetUser() {}
  useEffect(() => {
    document.getElementsByClassName("greeter")[0].style.height =
      window.innerHeight.toString() + "px";
  });
  return (
    <div
      className="greeter"
      onClick={() => {
        localStorage.setItem("visited", true);
        history.go();
      }}
    >
      
      <video style={{boxShadow:"white 0px 0px 0px 20px inset;"}} src="logo.mp4" autoPlay muted loop playsInline></video>
     
      <h>press to enter</h>
    </div>
  );
};
class Mobile extends Component {
  constructor() {
    super();

    console.log("hallo");
    this.visited = localStorage.getItem("visited") == "true" ? true : false;
  }

  render() {
    if (!this.visited) {
      return (
        <Router basename="/">
          <Route>
            <FirstVisit></FirstVisit>
          </Route>
        </Router>
      );
    }
    return (
      <Router basename="/">
        <Switch>
        <Route exact path="/contact">
        <Helmet>
            <title> {"Touching Screens - Contact"}</title>
          </Helmet>
          <Contact></Contact>
        </Route>
        <Route path="/">
          <Helmet>
            <title> {"Touching Screens"}</title>
          </Helmet>
          <Header />
        
        </Route>
        </Switch>        
        <Route exact path="/imprint">
          <Imprint></Imprint>
        </Route>
        
        
        
        <Route path="/swipe">
          <SwipeArtistInfo></SwipeArtistInfo>
        </Route>
        <Route path="/index">
          <>
            <Header />
            <Artists />
          </>
        </Route>
        <Route path="/a/b">
          <div>hallos</div>
        </Route>
        <Route path="/ttt">
          <div>hallos ttt</div>
        </Route>
        <Route path="/artist/:name">
          <ArtistComp />
        </Route>

        
        <Route exact path="/">
          <>
            <Artists />
          </>
        </Route>
        <Route path="/">
          <div>
            <Switch>
              <Route exact path="/contact">
                <></>
              </Route>
              <Route exact path="/imprint">
                <></>
              </Route>
              <Route exact path="/">
              
              </Route>
              <Route path="/">
                {" "}
                <Footer></Footer>
              </Route>
            </Switch>
          </div>
        </Route>
      </Router>
    );
  }
}
class SwipeArtistInfo extends Component {
  render() {
    return (
      <Swipe
        nodeName="div"
        className="test"
        onSwipeEnd={this.onSwipeEnd}
        onSwipedLeft={this.onSwipeLeftListener}
        onSwipedRight={this.onSwipeRightListener}
        onSwipedDown={this.onSwipeDownListener}
        onSwipedUp={this.onSwipeUpListener}
        onSwipe={this.onSwipeListener}
      >
        Demo
      </Swipe>
    );
  }
}

export default Mobile;
