import "./App.css";
import React, { Component } from "react";
import Desktop from "./Desktop/Desktop";
import Mobile from "./Mobile/Mobile";
import isIOS from 'react-device-detect';

class App extends React.Component {
  state = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  constructor() {
    super();
    this.handleWindowResize = this.handleWindowResize.bind(this);
  }

  handleWindowResize() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
    if (this.state.width > 845) {
      document.documentElement.style.setProperty('--header-height',document.getElementById("header-desktop").offsetHeight.toString()+"px");
      document.getElementById("header-logo").style.height = document.getElementById("header-font").offsetHeight.toString()+"px";
    }
    else{
      if (!isIOS){
      let headerheight = document.getElementById("header-mobile").offsetHeight;
      document.documentElement.style.setProperty('--header-height',(headerheight).toString()+"px");}
      
         }
   
  }

  componentDidMount() {

    window.addEventListener("resize", this.handleWindowResize);
    window.addEventListener("load",this.handleWindowResize);
    
    
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
    window.removeEventListener("load",this.handleWindowResize);
  }

  render() {
    if (this.state.width <= 845) {
      return <Mobile />;
    }
   
    return <Desktop />;
    
  }
}
export default App;
