import { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  useParams,
  Route,
  Link,
} from "react-router-dom";
import Helmet from "helmet";
import ArtistOverview from "./ArtistOverview";
import Header from "./Header";
import Artist from "./Artist";
import Spinn from "./Spinn";
import Imprint from "../Mobile/Imprint";
import Contact from "./Contact";

import { artist } from "../db";
class Desktop extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/contact">
          <Helmet>
            <title> {"Kontakt"}</title>
          </Helmet>
          <Contact></Contact>

          </Route>
        <Route path="/">
          <Helmet>
            <title> {"Touching Screens"}</title>
          </Helmet>
          <Header></Header>
        </Route>
        </Switch>
        <Route path="/index">
          <ArtistOverview />{" "}
        </Route>
        <Route exact path="/imprint">
          <Helmet>
            <title> Imprint</title>
          </Helmet>
          <Imprint></Imprint>
        </Route>
        <Route path="/artist/:name">
          <ArtistComp />{" "}
        </Route>
        
        <Route exact path="/">
          <ArtistOverview />
        </Route>
      </Router>
    );
  }
  Contact() {
    return <div>contact</div>;
  }
}
function ArtistComp() {
  let { name } = useParams();
  return <Artist id={name} />;
}
export default Desktop;
