import React, { Component } from "react";
import { Link } from "react-router-dom";

class Header extends Component {
  onPlay(){
    console.log(document.getElementById("header-desktop").offsetHeight);
    document.documentElement.style.setProperty('--header-height',document.getElementById("header-desktop").offsetHeight.toString()+"px");
    document.getElementById("header-logo").style.height = document.getElementById("header-font").offsetHeight.toString()+"px";
    document.getElementById("header-logo").style.display = "grid";
  }
  onEnded(){
    var vid=document.getElementById("header-font");
    setTimeout(()=>vid.play(),10000);
  }
  render() {
    return (
      <header id="header-desktop">
        <Link to="/">
          <video onCanPlay={this.onPlay} muted autoPlay playsInline onEnded={this.onEnded} id="header-font" src="/header.mp4"></video>
          <div id="logo-container">
            <video id="header-logo" src="/logo.mp4" muted autoPlay playsInline loop></video>
          </div>
        </Link>
      </header>
    );
  }
}
export default Header;
