import React, { Suspense, useEffect, useRef, useState } from "react";
import {
  Canvas,
  useLoader,
  useFrame,
  onPointerOver,
  onPointerOut,
} from "react-three-fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import duck from "../j-fntes_export.glb";
import { useSpring, animated } from "react-spring/three";

function Duck(props) {
  const spinner = useRef();
  const [skew, setSkew] = useSpring(() => ({
    from: {y: [0.3,0,0]},
    
    config: { mass: 5, tension: 350, friction: 40 },
  }));
  const [rotY,setRot]= useState({y:0});
  const rotate = useFrame(() => {
    spinner.current.rotation.x += 0.0;
    spinner.current.rotation.y += 0.01;
    setRot(spinner.current.rotation.y);
    spinner.current.rotation.z += 0.0;
  });
  const gltf = useLoader(GLTFLoader, props.model);
  
  return (
    <animated.primitive
      onPointerOver={() => setSkew({y:[0.8,spinner.current.rotation.y,spinner.current.rotation.z ]})}
      onPointerOut={() => setSkew({ y:[0.3,spinner.current.rotation.y,spinner.current.rotation.z ]})}
      rotation={skew.y}
      ref={spinner}
      object={gltf.scene}
      scale={[5, 5, 5]}
      position={[0, -4, 0]}
    />
  );
}

function Spinn(props) {
 
  

  return (
    <>
      <Canvas
        style={{ height: "100vh", width: "100vw" }}
        camera={{ position: [0, 0, 10] }}
      >
        <ambientLight intensity={0.8} />
        <spotLight intensity={1} position={[300, 300, 400]} />
        <Suspense fallback={null}>
          <Duck model={props.model}  />
        </Suspense>
      </Canvas>
    </>
  );
}

export default Spinn;
