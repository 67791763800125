import React, { Component } from "react";
import { Link } from "react-router-dom";
import { a } from "../db";
var myformat = new Intl.NumberFormat("en-US", {
  minimumIntegerDigits: 2,
  maximumIntegerDigits: 2,
  maximumFractionDigits: 0,
});

class Aside extends Component {
  constructor(props) {
    super(props);

    this.state = { entered: new Date(), time: 0, weather: null, temp: -10,date:new Date() };
  }

  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 10);
    fetch(
      "https://api.openweathermap.org/data/2.5/weather?q=Berlin&APPID=86009094842dea5993a7376c668c485c&units=metric"
    )
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          weather: result.weather[0].main,
          temp: result.main.temp,
        });
      });
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    this.setState({
      time: new Date() - this.state.entered,
      date: new Date()
    });
  }
  getDayByName() {
    var d = new Date();
    var weekday = new Array(7);
    weekday[0] = "Sunday";
    weekday[1] = "Monday";
    weekday[2] = "Tuesday";
    weekday[3] = "Wednesday";
    weekday[4] = "Thursday";
    weekday[5] = "Friday";
    weekday[6] = "Saturday";
    return weekday[d.getDay()];
  }
  render() {
    return (
      <aside className="sideBar">
        <div className="temps">
          <div className="week">
          {this.getDayByName()}<br></br>
          {this.state.date.getHours()+":"+this.state.date.getMinutes()}

          </div>
          

          <video muted autoPlay loop playsInline src={"/weather/" + ((this.state.weather === "Drizzle") ? "Rain": ((this.state.weather === "Atmosphere") ? "Clouds" : this.state.weather)) + ".mp4"}></video>
          <div className="weather">
          {this.state.weather}<br></br>
          {this.state.temp.toString().split('.')[0] + " °C"}
          </div>
        </div>
        <div className="positions">
          <div>
            {" "}
            x : {this.props.x}, y : {this.props.y}
          </div>
          {myformat.format(Math.floor(this.state.time / 1000 / 60)) + ":"}
          {myformat.format(Math.floor((this.state.time / 1000) % 60)) + ":"}
          {myformat.format(Math.floor((this.state.time / 10) % 100))}
        </div>
        <div className="contact-button-wrapper">
          {" "}
          <Link className="contact-button" to="/contact">
            Kontakt
          </Link>
        </div>
      </aside>
    );
  }
}

export default Aside;
