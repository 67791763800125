import { Component } from "react";
import Aside from "./Aside";
import { artist, main } from "../db";
import TiltPhaseSix from "../TiltPhaseSix";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

class Artist extends Component {
  state = {
    x: 0,
    y: 0,
  };
  constructor(props) {
    super(props);
    this.selected = artist.filter((x) => x.id == this.props.id)[0];
    console.log(this.selected);
    this.socials = [];

    for (var key in this.selected.social) {
      this.socials.push(
        <a href={this.selected.social[key]} target="_blank">
          {" "}
          {key.charAt(0).toUpperCase() + key.slice(1)}{" "}
        </a>
      );
    }
    /* this.socials.push(<Link to="/">Zurück</Link>) */
    console.log(this.socials);
  }
  componentDidMount() {
    if (this.props.onWindowScroll)
      window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    if (this.props.onWindowScroll)
      window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    return (
      <div className="App">
        <Helmet>
          <title>{this.selected.name + " - Touching Screens"}</title>
        </Helmet>
        <main
          onMouseMove={(e) => {
            this.setState({
              x: e.nativeEvent.clientX,
              y: e.nativeEvent.clientY,
            });
          }}
        >
          <footer className="border-right">
            <a href="imprint">Imprint</a>
          </footer>
          <section id="artist-text" className="border-right">
            <div id="info">
              <h3> {this.selected.name}</h3>
              <p> {this.selected.desc}</p>
            </div>
            <div id="social">
              <span id="links"> {this.socials}</span>
            </div>
            
              <TiltPhaseSix
                id="img-container"
                options={{
                  max: 30,
                  perspective: 1000,
                  scale: 0.95,
                }}
              >
                <img src={this.selected.images[0]}></img>
              </TiltPhaseSix>{" "}
          
            <Link class="artist-back" to="/">Back</Link>
          </section>
          <section id="preview" className="border-right">
            <div>
              <video
                style={{ objectFit: "cover" }}
                id="preview-video"
                src={"/" + this.selected.id.toLowerCase() + ".mp4"}
                autoPlay
                muted
                loop
              ></video>
            </div>
          </section>
          <Aside x={this.state.x} y={this.state.y}></Aside>
        </main>
      </div>
    );
  }
}

export default Artist;
