import { useEffect } from "react";

const Contact = () => {
  const back = () => {
    document.body.style.backgroundColor = "white";
    document.body.style.Color = "white";
    document.getElementById("root").backgroundColor = "white";
    window.history.back();
  };
  useEffect(() => {
    document.getElementsByClassName("contact")[0].style.height =
      window.innerHeight.toString() + "px";
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
    document.documentElement.style.height =
      window.innerHeight.toString() + "px";
    document.body.style.backgroundColor = "black";
    document.getElementById("root").backgroundColor = "black";
    document.body.style.Color = "black";
  
  });
  return (
    <div class="contact">
      <img src={"pics/card_01_mobile.jpeg"}></img>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <a
          href="mailto:contact@touchingscreens.com"
          style={{
            position: "absolute",
            height: "48%",
            top: "27%",
            width: "90%",
          }}
        ></a>
      </div>
      <div class="back-button-wrapper">
        <div class="back-button" onClick={back}>
          Back
        </div>
      </div>
    </div>
  );
};

export default Contact;
