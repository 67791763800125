import React, { useEffect, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { isIOS, isMobileOnly } from "react-device-detect";
import { artist } from "../db";

import { Helmet } from "react-helmet";
import { useParams, useHistory } from "react-router-dom";
import {
  config,
  useSpring,
  animated,
  interpolate,
  to,
  useSprings,
} from "react-spring";
function onSwipe(index, indexLatest, meta) {
  console.log(index);
}
function test() {
  let showen = localStorage.getItem("tutorial");
  if (showen != "showed") {
    document.getElementById(
      "slide"
    ).firstElementChild.style.animationPlayState = "running";
  } else {
    document.getElementById("tutorial").style.display = "none";
  }
  localStorage.setItem("tutorial", "showed");
}

const AnimatedSwipe = animated(SwipeableViews);
function MobileArtist() {
  const [emulate, startEmulation] = useState(false);
  let history = useHistory();
  let { name } = useParams();
  let selected = artist.filter((x) => x.id == name)[0];
  console.log(selected);
  let socials = [];
  let [count, setCount] = useState(1);

  for (var key in selected.social) {
    socials.push(
      <a href={selected.social[key]} target="_blank">
        {key.charAt(0).toUpperCase() + key.slice(1)}{" "}
      </a>
    );
  }

  const styles = {
    slide: {
      minHeight: "calc(100vh - var(--header-height))",
      height: "fit-content",
      color: "#fff",
    },
    div: {
      display: "flex",
      justifyContent: "center",
    },
    video: {
      height: "calc(100vh - var(--header-height))",
    },
  };
  var indexx = 0;

  useEffect(() => {
    console.log("nowww");
    let headerheight = document.getElementById("header-mobile").offsetHeight;
    document.documentElement.style.setProperty(
      "--header-height",
      headerheight.toString() + "px"
    );
    document
      .getElementById("artist-mobile-spinn")
      .getElementsByTagName("video")[0].style.height =
      (window.innerHeight - headerheight - 30).toString() + "px";
    document.getElementById("images-mobile").style.height =
      (window.innerHeight - headerheight - 10).toString() + "px";
  }, [count]);
  let last = new Date();
  last = last.getMilliseconds();
  let yoo = () => {
    var x = new Date();
    if (x.getMilliseconds() - last > 300) {
      if (!isIOS) {
        setCount(count + 1);
        last = x;
      }
    }
  };
  window.addEventListener("resize", yoo);

  const props = useSpring({
    from: { width: "40%" },
    to: { width: "100%" },
    config: { mass: 0.9, friction: 18, tension: 220, presicion: 0.01 },
    onRest() {
      test();
    },
  });
  const emulateSwipe = useSpring({ x: emulate ? 1 : 0 });

  return (
    <>
      <Helmet>
        <title>{selected.name + " - Touching Screens"}</title>
      </Helmet>
      <AnimatedSwipe
        /* style={{transform: emulateSwipe.x.to({
          range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
          output: [0, -100, -100, -100, -100, -100, -100, 0]
        }).to((x) => `translateX(${x}%)`)}} */

        id="slide"
        animateTransitions
        hysteresis={0.3}
        containerStyle={Object.assign({ WebkitOverflowScrolling: "touch" })}
        resistance
        enableMouseEvents
        disableLazyLoading
        index={1}
        onChangeIndex={(index) => {
          if (index == 4 || index == 0) history.push("/");
        }}
      >
        <div id="redirect"></div>
        <div
          style={Object.assign({}, styles.slide, styles.slide1, styles.video)}
        >
          <div id="artist-mobile-spinn">
            <div id="tutorial">
              swipe <br />
              to see <br />
              more
            </div>
            <animated.video
              src={"/" + selected.id.toLowerCase() + ".mp4"}
              muted
              playsInline
              autoPlay
              loop
              style={props}
            ></animated.video>
          </div>
        </div>

        <div style={Object.assign({}, styles.div)}>
          <div id="artist-text-mobile">
            <h3>{selected.name.toUpperCase()}</h3>
            <div id="mobile-text-container">
              <p>{selected.desc}</p>
              <div id="mobile-socials">
                {socials.map((x) => (
                  <span>{x}</span>
                ))}{" "}
              </div>
            </div>

            {/* <Link to="/contact">kontakt</Link> */}
          </div>
        </div>
        <div style={Object.assign({})}>
          <div id="artist-info-mobile">
            <div id="images-mobile">
              {selected.images.map((x) => (
                <img src={x}></img>
              ))}{" "}
            </div>
          </div>
        </div>
        <div id="redirect"></div>
      </AnimatedSwipe>
    </>
  );
}
export default MobileArtist;
