export const artist = [
  {
    id: "NullSechsRoy",
    name: "NullSechsRoy",
    desc: "Ich mach Pop Musik mit different influences von trap über hiphop zu rock etc. Ich merge verschiedene Musikrichtungen in einer. Ich will Musik nachhaltig prägen und Genregrenzen verschwinden lassen habub",
    social: {
      instagram: "https://www.instagram.com/nullsechsroy/",
      spotify: "https://open.spotify.com/artist/2rppc2E2UKZKAwHQnWaw4s",
      youtube: "https://youtu.be/fAC25kk8_F4",
    },
    images: [
      "/pics/roy4.jpeg",
      "/pics/roy3.jpeg",
      "/pics/roy2.jpeg",
      "/pics/roy1.jpeg",
    ],
  },
  {
    id: "Baugruppe90",
    name: "Baugruppe90",
    desc: "Wir machen Techno wie er sein muss, ohne Regeln der guten Schule. Dabei ist die Bus-Zerre unsere Alltagswaffe im täglichen Kampf gegen weiche Drums. Gemeinsam wollen wir einen deftigen Abdruck in der elektronischen Musiklandschaft hinterlassen und bei intimen Clubshows die Menge begeistern",
    social: {
      instagram: "https://instagram.com/baugruppe90/",
      spotify: "https://open.spotify.com/artist/6pKJqozqp0jdugOsyFRGM",
      youtube: "https://youtu.be/uLeJHDnWDqk",
    },
    images: [
      "/pics/bg1.jpeg",
      "/pics/bg2.jpeg",
      "/pics/bg3.jpeg",
      "/pics/bg4.jpeg",
    ],
  },
  {
    id: "young-aj",
    name: "Young Aj",
    desc: "Frankfurtertrapgossip aus der Perspektive von AJ. Ich will mehr vom Meer.",
    social: {
      instagram: "https://instagram.com/youngaj_nme",
      spotify: "https://open.spotify.com/artist/4dChD7zaG4xx7mo3CraalG",
      youtube: "https://youtu.be/fNqsw7qDT4o",
    },
    images: ["/pics/ya2.jpeg", "/pics/ya1.jpeg", "/pics/ya3.jpeg"],
  },
  {
    id: "l1th",
    name: "L1th",
    desc: "isual Artists die als Kollektiv agieren. Ich bin kreativ. Das Ich könnte auch ein Wir sein. Wir machen mit Sicherheit nicht dass, was es schon gibt. Ich will einen Porsche-Jeep, Taschen auf Rücksitz.",
    images: ["/pics/b_image.jpeg"],
  },
  {
    id: "j-fontes",
    name: "Fontes",
    desc: "Ich mach Musik die im Kopf bleibt und berührt. Jeden irgendwie anders. Genres und Kategorisierungen sind da zweitrangig. Es geht ums Gefühl und mein Goal die Kultur zu prägen und mir ein fetten Flügel zu kaufen 🙌🏿💞😔",
    images: [
      "/pics/jf2.jpeg",
      "/pics/jf3.jpeg",
      "/pics/jf4.jpeg",
      "/pics/jf1.jpeg",
    ],

    social: {
      spotify: "https://open.spotify.com/artist/425IHCrVR8q2lyNDRfbf49",
    },
  },
  {
    id: "niko",
    name: "Niko Avgerinos",
    social: {
      email: "mailto:avgerinos.niko@gmail.com",
      instagram: "https://www.instagram.com/nikoavgerinos/",
    },
    desc: "Die meiste Zeit im Studio am Knöpfe drehen. Zwischen Production, Samples und Score to Picture ist alles dabei… Hit me for a pack",
    images: ["/pics/niko1.jpeg", "/pics/niko2.jpeg", "/pics/niko3.jpeg"],
  },

  {
    id: "kim",
    name: "Yin",
    images: ["/pics/kim1.jpeg", "/pics/kim2.jpeg", "/pics/kim3.jpeg"],
    social: {
      spotify: "https://open.spotify.com/artist/4L6YmCryiH7lv6pwfzzKAd",
      instagram: " https://instagram.com/golden.yin",
      youtube: "https://youtube.com/channel/UCGnJIm0AQd6pv2fzNXLG0Ig",
    },
    desc: "Producer, Songwriter oder Artist, egal was, ich will nur Musik machen, die deine Seele berührt. Ziel ist: Sauce into the Culture bringen und die Family out the mud zu holen. Love.",
  },
  {
    id: "ayln",
    name: "ayln",
    desc: "Ich fühle mich überall, aber auch nirgendwo Zuhause. Das gilt auch für meine Musik, Genres werden übernommen und verändert. So wie ein Alien seinen neuen Planeten.",
    social: {
      instagram: "https://instagram.com/ayln069",
    },
    images: ["/pics/alyn1.jpeg", "/pics/alyn2.jpeg", "/pics/alyn3.jpeg","/pics/alyn4.jpeg", "/pics/alyn5.jpeg"],
  },
];
export const main = "logo_1.mp4";
export var a = 3;
