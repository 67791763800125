const Contact = () => {
  const back = () => {
    window.history.back();
  };
  return (
    <div class="contact">
      <img src={"/pics/card_01_desktop.jpeg"}></img>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <a
          href="mailto:contact@touchingscreens.com"
          style={{
            position: "absolute",
            height: "34%",
            top: "33%",
            width: "42%",
          }}
        ></a>
      </div>
      <div class="back-button-wrapper">
        <div class="back-button" onClick={back}>
          Back
        </div>
      </div>
    </div>
  );
};

export default Contact;
